import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  QuickFlowStepsEntity, RefdocWithShortyOutput,
  ReferenceDocumentEntity,
} from "../../generated/graphql";
import { AnimatedContent } from "../../components/animated-content/animated-content";
import EditorJsParser from "editorjs-parser";
import {
   notification,
} from "antd";

import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  ShareIcon,
  XIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import { StepIndicator } from "./step-indicator";
import { useSwipeable } from "react-swipeable";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "antd";
import { QfShare } from "./qf-share";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TTS from "../../components/tts/tts";

const parser = new EditorJsParser(
  {},
  {
    video: (data: any, config: any) => {
      return `
        <div class="mstr-video">
          <video controls src="${data.url}" />
        </div>
      `;
    },
    paragraph: (data: any, config: any) => {
      return `<div>${data.text}</div>`;
    },
    image: (data: any, config: any) => {
      return `
        <div class="mstr-image">
          <img src="${data.url || data.file.url}" />
        </div>
      `;
    },
    delimiter: (data: any, config: any) => {
      return `
        <div class="mstr-delimiter">
          <br />
        </div>
      `;
    },
  }
);

interface REFDOC {
  referenceDocumentWithShorty: RefdocWithShortyOutput;
}
interface Props {
  refDocId?: Number;
  closePreview: Function;
  hideClose?: boolean;
  hideShare?: boolean;
}

const REFERENCE_DOCUMENT = gql`
  query ReferenceDocumentWithShorty($id: Int!) {
    referenceDocumentWithShorty(id: $id) {
      refdoc {
        quickFlowSteps {
          id
          title
          content
        }
        id
        document_name
        chapter
      }
      shorty {
        id
        url
        qfid,
        uuid
      }
    }
  }
`;

export const QuickFlowModal = (props: Props) => {
  //   const document_id = (props as any).match.params.id;
  const document_id = props.refDocId;

  const [counter, setCounter] = useState(0);
  const [step, setStep] = useState<QuickFlowStepsEntity>();
  const history = useHistory();
  const [completedSteps, setCompletedSteps] = useState<{
    [key: string]: boolean;
  }>({});
  const [shareModel, setShareModel] = useState(false);
  const [paused, setPaused] = useState(false);

  const { data, refetch, loading } = useQuery<REFDOC>(REFERENCE_DOCUMENT, {
    variables: {
      id: document_id,
    },
  });

  const next = () => {
    if (counter + 1 === data?.referenceDocumentWithShorty.refdoc.quickFlowSteps.length) {
      return false;
    }
    if (step) {
      setTimeout(() => {
        setCompletedSteps({
          ...completedSteps,
          [step.id]: true,
        });
      }, 300);
    }
    setCounter(counter + 1);
  };

  const previous = () => {
    if (counter === 0) {
      return false;
    }
    if (step) {
      setTimeout(() => {
        setCompletedSteps({
          ...completedSteps,
          [step.id]: true,
        });
      }, 300);
    }
    setCounter(counter - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      next();
    },
    onSwipedRight: () => {
      previous();
    },
  });
  // var url = document.location.href.split('ih')[0] + 'ih/quickflow/' + data?.referenceDocumentWithShorty.refdoc.id ;
  let url = 'https://mindstrength.co/'+data?.referenceDocumentWithShorty.shorty.uuid ;

  const share = () => {
    if(navigator.share){
      
      navigator.share({
        text: `Check out this MindStrength Quickflow: ${data?.referenceDocumentWithShorty.refdoc.document_name }`,
        url: url,
        title: `Quickflow: ${data?.referenceDocumentWithShorty.refdoc.document_name}`
      })
    } else{
      navigator.clipboard.writeText(url);
      notification.success({
      message: "Link copied to clipboard",
      });    
    }

  }

  useEffect(() => {
    const steps = data?.referenceDocumentWithShorty.refdoc.quickFlowSteps || [];
    setStep(steps[counter]);
  }, [counter, data]);

  if (loading) {
    return <div>Loading</div>;
  }

  if (!loading) {
    const steps = data?.referenceDocumentWithShorty.refdoc.quickFlowSteps || [];
    // const markup = parser.parse(step?.content || []);
    return (
      <div
        {...handlers}
        className="flex-1 overflow-auto flex flex-col"
        style={{
          fontSize: 17,
        }}
      >
        <div className="flex flex-col flex-1 overflow-auto">
          <div className="flex justify-between cursor-pointer px-4 text-base font-bold bg-gray-200 py-3 m-3 rounded-md">
            <div>
              <div>{data?.referenceDocumentWithShorty.refdoc.document_name}</div>
            </div>
            <div className="flex">
              {!props.hideShare && <ShareIcon
                onClick={() => {
                  setShareModel(true);
                }}
                className="w-6 h-6 ml-6 text-blue-400 "
              />}
              {!props.hideClose && <XIcon
                onClick={() => props.closePreview(false)}
                className="w-6 h-6 ml-6"
              />}
            </div>
          </div>
          <div className="px-6 text-blue-400 font-bold">{step?.title}</div>

          <div className="p-6">
            {step && <TTS pause={paused} showTextLabel={true} key={step.id} id={step.id} connector={""} url="/api2/reference-document/speech/qf" />}
          </div>

          <div className="flex-1 overflow-auto px-6 py-2 pt-3 qf-step">
            {step && step.content && !completedSteps[step.id] && (
              <AnimatedContent
                key={step.id}
                data={step?.content}
                mode="QUICKFLOW"
                onVideoStart={() => {
                  setPaused(true);
                }}
                onVideoPause={() => {
                  setPaused(false);
                }}
              />
            )}
            {step && step.content && completedSteps[step.id] && (
              <div
                key={step.id}
                dangerouslySetInnerHTML={{
                  __html: parser.parse(step?.content || []),
                }}
              />
            )}
          </div>
        </div>

        {steps.length > 7 && (
          <div className="h-1 w-full bg-gray-100">
            <div
              className="h-1 bg-red-400"
              style={{
                width:
                  ((counter + 1) /
                    data?.referenceDocumentWithShorty.refdoc.quickFlowSteps.length!) *
                    100 +
                  "%",
              }}
            />
          </div>
        )}

        {steps.length <= 7 && (
          <StepIndicator totalSteps={steps.length} currentStep={counter} />
        )}

        <div className="flex justify-between p-4 items-center">
          <button
            onClick={() => {
              previous();
            }}
            disabled={counter === 0}
            className={classNames(
              "flex items-center",
              counter === 0 ? "opacity-40" : ""
            )}
          >
            <ChevronLeftIcon className="w-7 h-7" />
            <div className="text-base">Prev</div>
          </button>
          {/* <div className="flex-1 text-center text-base font-bold">
            {step?.title}
          </div> */}
          <button
            onClick={() => {
              next();
            }}
            disabled={
              counter + 1 === data?.referenceDocumentWithShorty.refdoc.quickFlowSteps.length
            }
            className={classNames(
              "flex items-center",
              counter + 1 === data?.referenceDocumentWithShorty.refdoc.quickFlowSteps.length
                ? "opacity-40"
                : ""
            )}
          >
            <div className="text-base">Next</div>
            <ChevronRightIcon className="w-7 h-7" />
          </button>
        </div>
        {shareModel && (
          <Modal
            className="sharemodal"
            visible
            title={`Share '${data?.referenceDocumentWithShorty.refdoc.document_name}'`}
            destroyOnClose={true}
            onCancel={() => {
              setShareModel(false);
            }}
            footer={
              <div className="flex justify-between">
                <Tippy content="Copy Link">

                <div>
                <img 
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  notification.success({
                    message: "Link copied to clipboard",
                  });                    }}
                // className="bg-indigo-500 text-white rounded text-sm py-2 px-5 mx-2 hover:bg-indigo-600"

                 className="h-10 w-10 " 
                 src="https://d205v7va10brr8.cloudfront.net/5020ee50-2f4e-409f-8168-4abd25ff12f1.png" />

                </div>
                </Tippy>

                <div>
                  <button 
                    type="button"
                    className="inline-flex items-center rounded-lg border border-transparent  p-2 text-white shadow-sm border-blue-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <button
                      onClick={share}
                      className="text-sm flex text-blue-400 mr-2 "
                    >
                      Share via
                    </button>
                    <img
                      onClick={share}
                      src="https://d205v7va10brr8.cloudfront.net/7f847286-d361-465a-9891-b855ec077074.png"
                      className="h-4 w-4 text-white"
                    />
                  </button>
                  {/* <button onClick={share} className="text-sm flex text-blue-400 ">Share the link via</button> */}
                </div>
              </div>
            }
          >
            <QfShare
              document={data?.referenceDocumentWithShorty.refdoc!}
            />
          </Modal>
        )}
      </div>
    );
  }

  return <div>SWR</div>;
};
