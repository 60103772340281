import React, { useEffect, useState } from "react";
import { eventBus } from "../../../../event-bus/event-bus";
import { QuestionEntity } from "../../../../generated/graphql";
import { FadeIn } from "../../../fade-in/fade-in";
import { MstrButton } from "../../../mstr-button/mstr-button";
import { TypeText } from "../../../type-text/type-text";
import "./pre-text.scss";
import TTS from "../../../tts/tts";

interface Props {
  question: QuestionEntity;
  showQuestionContent: Function;
  previousQuestionId: number;
  questionIndex: number;
  userListenedToElementSpeech?: boolean;
}

const getRandomLanguage = () => {
  const messages = [
    "Let's move ahead to the next part:",
    `Now that we have that context let's cover some more ground.`,
    `Let's keep going and go deeper on this.`,
    `Let's continue and understand the following aspect.`,
  ];
  return messages[Math.floor(Math.random() * messages.length)];
};

export const PreText = (props: Props) => {
  const [blurPreText, setBlurPreText] = useState(false);
  const [preTextShown, setPreTextShown] = useState(false);
  const [showPreTextContButton, setShowPreTextContButton] = useState(true);
  const [shouldShowPreText, setShouldShowPreText] = useState(false);
  const [connectorLanguage] = useState(getRandomLanguage());
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (
      props.previousQuestionId === props.question.id ||
      !props.question.static_content ||
      !props.question.static_content.blocks.length
    ) {
      eventBus.dispatch("ENABLE_AUTO_SCROLL", {});
      setTimeout(() => {
        props.showQuestionContent();
      }, 300);
    } else {
      setShouldShowPreText(true);
    }
  }, []);

  const scrollDownPreText = () => {
    setTimeout(() => {
      console.log("scrollDownPreText");
      let container = document.getElementsByClassName(
        "section-content-container"
      )[0];
      container.scrollTo({
        top: container.scrollTop + 500,
        behavior: "smooth",
      });
    }, 1500);
  };

  return (
    <>
      {shouldShowPreText && (
        <div className={`${blurPreText ? "fade-section" : ""}`}>
          {props.questionIndex !== 0 && (
            <TypeText onComplete={() => {
              setPreTextShown(true);
              scrollDownPreText();
            }}>
              {connectorLanguage}
            </TypeText>
          )}

          {(preTextShown || props.questionIndex === 0) && (
            <div className="question-content pt-1">
              {props.userListenedToElementSpeech && (
                <TTS pause={paused} className="float-left pr-3 " id={props.question.id} key={props.question.id} connector={""} url="/api2/question/speech/pre-text" />
              )}
              <div
                // className="section-content"
                dangerouslySetInnerHTML={{
                  __html: (props.question as any).static_content_html,
                }}
              ></div>
              {/* <AnimatedContent
                mode="QUESTION"
                onComplete={() => setShowPreTextContButton(true)}
                data={props.question.static_content}
              /> */}
              <br />
              {showPreTextContButton && (
                <FadeIn>
                  <MstrButton
                    text="Continue"
                    onClick={() => {
                      setBlurPreText(true);
                      setPaused(true);
                      eventBus.dispatch("ENABLE_AUTO_SCROLL", {});
                      props.showQuestionContent();
                    }}
                  />
                </FadeIn>
              )}
              <br />
            </div>
          )}
        </div>
      )}
    </>
  );
};
