import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { eventBus } from "../../event-bus/event-bus";
import { AnimatedContent } from "../animated-content/animated-content";
import { FadeIn } from "../fade-in/fade-in";
import { MstrButton } from "../mstr-button/mstr-button";
import { TypeText } from "../type-text/type-text";
import "./additional-content.scss";
import { QuestionEntity } from "../../generated/graphql";

interface Props {
  question: QuestionEntity;
  // explanation: Object;
  // attemptFeedbackMessage: string;
  onComplete: Function;
  // shouldShowExplanation: boolean;
  isCorrect: string;
  uuid: any;
  initiative_id: number;
  section_id: number;
  question_id: number;
}

const getRandomLanguage = () => {
  const messages = [
    "Here's some additional information for you",
  ];
  return messages[Math.floor(Math.random() * messages.length)];
};

export const QuestionAdditionalContent = (props: Props) => {
  const [isFeedbackMessageTyped, setFeedbackMessageTyped] = useState(false);
  const [isContinueButtonShown, showContinueButton] = useState(false);

  const [explanationStartTime, setExplanationStartTime] = useState<Date>(
    new Date()
  );

  const { question, onComplete, uuid, initiative_id, section_id, question_id, isCorrect } = props;
  const [connectorLanguage] = useState(getRandomLanguage());

  const hasAdditionalContent = question.post_content && question.post_content.blocks && question.post_content.blocks.length > 0 ? true : false;

  // As the question will repeat until attempt is YES or CNBC, show additional content only in their case
  if (!hasAdditionalContent || !question.show_additional_content || isCorrect === "NO") {
    return (
      <div>
        <div className="question-additional-content" id={`additional-content-${uuid}`}>
          <TypeText
            delay={1000}
            onComplete={() => {
              onComplete();
            }}
          >
            <div>&nbsp;</div>
          </TypeText>
          <br />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="question-explanation" id={`explanation-${props.uuid}`}>
        <TypeText
          delay={1000}
          onComplete={() => {
            setFeedbackMessageTyped(true);
          }}
        >
          {connectorLanguage}
        </TypeText>
        <br />
        {isFeedbackMessageTyped && (
          <AnimatedContent
            initiative_id={initiative_id}
            section_id={section_id}
            question_id={question_id}
            mode="ADDITIONAL-CONTENT"
            pauseTextOnPeriod={true}
            onComplete={() => {
              showContinueButton(true);
            }}
            data={question.post_content}
          />
        )}
      </div>
      {isContinueButtonShown && (
        <FadeIn animateHeight={true}>
          <br /> <br />
          <MstrButton
            text="Got it! Thanks"
            onClick={() => {
              showContinueButton(false);
              onComplete();
            }}
          />
          <br /> <br />
        </FadeIn>
      )}
    </div>
  );
};
