import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { ReferenceDocumentEntity } from "../../generated/graphql";
import EditorJsParser from "editorjs-parser";
import { ShareIcon, XIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { Modal, notification } from "antd";
import { MdfShare } from "./mdf-share";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TTS from "../../components/tts/tts";

const parser = new EditorJsParser(
  {},
  {
    video: (data: any, config: any) => {
      return `
        <div class="mstr-video">
          <video controls src="${data.url}" />
        </div>
      `;
    },
    paragraph: (data: any, config: any) => {
      return `<div class="my-4">${data.text}</div>`;
    },
    image: (data: any, config: any) => {
      return `
        <div class="mstr-image mt-4">
          <img src="${data.url || data.file.url}" />
        </div>
      `;
    },
    delimiter: (data: any, config: any) => {
      return `
        <div class="mstr-delimiter">
          <br />
        </div>
      `;
    },
  }
);

interface REFDOC {
  referenceDocument: ReferenceDocumentEntity;
}
interface Props {
    refDocId?: Number
    closePreview: Function;
    hideClose?: boolean;
    hideShare?: boolean;
}

const REFERENCE_DOCUMENT = gql`
  query ReferenceDocument($id: Int!) {
    referenceDocument(id: $id) {
      id
      content
      document_name    
    }
  }
`;

export const CustomRefdocModal = (props: Props) => {
  const [shareModel, setShareModel] = useState(false);
  const document_id = props.refDocId


  const { data, loading } = useQuery<REFDOC>(REFERENCE_DOCUMENT, {
    variables: {
      id: document_id,
    },
  });
  
  let url = document.location.href.split("ih")[0] + "ih/custom-refdoc/" + document_id;
  const share = () => {
    if(navigator.share){
      
      navigator.share({
        text: `Check out this MindStrength MDF: ${data?.referenceDocument.document_name }`,
        url: url,
        title: 'MDF'
      })
    } else{
      navigator.clipboard.writeText(url);
      notification.success({
      message: "Link copied to clipboard",
      });    
    }

  }

  if (loading) {
    return <div>Loading</div>;
  }

  if (!loading) {
    return (
      <div
        className="flex-1 overflow-auto flex flex-col"
        style={{
          fontSize: 17,
        }}
      >
        <div className="flex justify-between cursor-pointer px-4 text-base font-bold bg-gray-200 py-3 m-3 rounded-md">
          {data?.referenceDocument.document_name} 
          <div className="flex">

          {!props.hideShare && <ShareIcon
                onClick={() => {
                  setShareModel(true);
                }}
                className="w-6 h-6 ml-6 text-blue-400 "
              />}
          {!props.hideClose && <XIcon onClick={() => props.closePreview(false)} className="w-6 h-6 ml-6" />}
          </div>

        </div>


        <div className="px-6 pt-6">
            {data?.referenceDocument.content && <TTS showTextLabel={true} key={data.referenceDocument.id} id={data.referenceDocument.id} connector={""} url="/api2/reference-document/speech/mdf" />}
        </div>

        <div
          className="p-6 flex-1 overflow-auto"
          dangerouslySetInnerHTML={{
            __html: parser.parse(
              data?.referenceDocument.content || { blocks: [] }
            ),
          }}
        ></div>


          {shareModel && (
          <Modal
            className="sharemodal"
            visible
            title={`Send '${data?.referenceDocument.document_name}'`}
            destroyOnClose={true}
            onCancel={() => {
              // setPcName("");
              setShareModel(false);
            }}
            footer={
              <div className="flex justify-between">
                <Tippy content="Copy Link">
                <div>
                <img 
                onClick={() => {
                  console.log({
                    url,
                  });
                  navigator.clipboard.writeText(url);
                  notification.success({
                    message: "Link copied to clipboard",
                  });                    }}

                 className="h-10 w-10 " 
                 src="https://d205v7va10brr8.cloudfront.net/5020ee50-2f4e-409f-8168-4abd25ff12f1.png" />

                </div>
                </Tippy>

                <div>
                  <button 
                    type="button"
                    className="inline-flex items-center rounded-lg border border-transparent  p-2 text-white shadow-sm border-blue-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <button
                      onClick={share}
                      className="text-sm flex text-blue-400 mr-2 "
                    >
                      Share via
                    </button>
                    <img
                      onClick={share}
                      src="https://d205v7va10brr8.cloudfront.net/7f847286-d361-465a-9891-b855ec077074.png"
                      className="h-4 w-4 text-white"
                    />
                  </button>
                  {/* <button onClick={share} className="text-sm flex text-blue-400 ">Share the link via</button> */}
                </div>
              </div>
            }
          >
            <MdfShare
              document={data?.referenceDocument!}
            />
          </Modal>
        )}
      </div>
    );
  }

  return <div>SWR</div>;
};
