import React, { useEffect, useState } from "react";
import { AnimatedContent } from "../animated-content/animated-content";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { answerVariants } from "./question.framer-variants";
import { Answer } from "../answer/answer";
import { LearnerDetailsOutput, QuestionEntity } from "../../generated/graphql";
import { QuestionExplanation } from "../explanation/explanation";
import { DotSeparator } from "../dot-separator/dot-separator";
import { FadeIn } from "../fade-in/fade-in";
import "./question.component.scss";
import { AnswerList } from "../answer-list/answer-list";
import { AnswerText } from "../answer-text/answer-text";
import { IQuestionAttempt } from "../../screens/section-questions/section-questions";
import { TypeText } from "../type-text/type-text";
import { MstrButton } from "../mstr-button/mstr-button";
import { QuestionStarter } from "./components/question-starter/question-starter";
import { PreText } from "./components/pre-text/pre-text";
import { QuestionContent } from "./components/question-content/question-content";
import { QuestionAnswer } from "./components/question-answer/question-answer";
import { getFontSize } from "../../AppFont";
import { QuestionAdditionalContent } from "../additional-content/additional-content";
import TTS from "../tts/tts";

interface Props {
  onQuestionAttempt: IQuestionAttempt;
  question: QuestionEntity;
  onQuestionFlowComplete: any;
  isQuestionResponseLoading: boolean;
  currentQuestionUUID: number;
  questionIndex: number;
  previousQuestionId: number;
  learnerDetails: LearnerDetailsOutput;
  isCorrect: string;
  initiative_id: number;
  section_id: number;
  userListenedToElementSpeech: boolean;
}

export interface IOnAnswerSelect {
  (answer_id: number, answer_text: string): void;
}

export const Question = (props: Props) => {
  const [isQuestionTyped, setQuestionTyped] = useState<boolean>(false);

  const [isDotSeparatorShown, showDotSeparator] = useState(false);
  const [isStaticContentShown, setStaticContentShown] = useState(false);

  const [paused, setPaused] = useState(false);

  const [questionStartTime, setQuestionStartTime] = useState<Date>();

  const [
    isQuestionStarterLanguageShown,
    setQuestionStarterLanguageShown,
  ] = useState(false);

  const questionTyped = () => {
    setBlocksShown([...blocksShown, "ANSWER"]);
    setQuestionStartTime(new Date());
  };

  const onExplanationDone = () => {
    setBlocksShown([...blocksShown, "ADDITIONAL-CONTENT"]);
  }

  // useEffect(() => {
  //   if (props.questionIndex === 0) {
  //     setBlocksShown([...blocksShown, "PRE_TEXT"]);
  //   } else {
  //     setBlocksShown([...blocksShown, "STARTER_LANGUAGE"]);
  //   }
  // }, [props.questionIndex]);

  const questionFlowComplete = () => {
    setBlocksShown([...blocksShown, "DOT_SEPARATOR"]);
    props.onQuestionFlowComplete();
    document
      .querySelectorAll(`#mstr-question-${props.question.id} video`)
      .forEach((video: any) => video.pause());
  };

  const setPrequestionContentShown = () => { };

  useEffect(() => {
    if (props.questionIndex === 0) {
      setPrequestionContentShown();
    }
  }, []);

  // useEffect(() => {
  //   console.log("Question CHANGED: ", props.question);
  // }, [props.question]);

  const isCurrentQuestion: boolean =
    props.currentQuestionUUID === (props.question as any).uuid;

  const onQuestionStarterComplete = () => {
    setQuestionStarterLanguageShown(true);
    setPrequestionContentShown();
  };

  const blocksToShow = [
    "STARTER_LANGUAGE",
    "PRE_TEXT",
    "QUESTION_CONTENT",
    "ANSWER",
    "EXPLANATION",
    "DOT_SEPARATOR",
  ];

  const [blocksShown, setBlocksShown] = useState<Array<string>>(["PRE_TEXT"]);
  const showQuestionContent = () => {
    setBlocksShown([...blocksShown, "QUESTION_CONTENT"]);
  };

  return (
    <div
      style={{
        fontSize: getFontSize(),
      }}
      id={`mstr-question-${props.question.id}`}
      className={classNames("question-container", {
        "previous-question": !isCurrentQuestion,
        "current-question": isCurrentQuestion,
      })}
    >

      {blocksShown.includes("PRE_TEXT") && (
        <div className="pt-1">
            <PreText
              questionIndex={props.questionIndex}
              previousQuestionId={props.previousQuestionId}
              question={props.question}
              showQuestionContent={showQuestionContent}
              userListenedToElementSpeech={props.userListenedToElementSpeech}
            />
        </div>
      )}

      {blocksShown.includes("QUESTION_CONTENT") && (
        <>
          <QuestionContent
            pause={paused}
            questionIndex={props.questionIndex}
            previousQuestionId={props.previousQuestionId}
            userListenedToElementSpeech={props.userListenedToElementSpeech}
            learnerDetails={props.learnerDetails}
            question={props.question}
            onComplete={questionTyped}
          />
        </>
      )}

      {blocksShown.includes("ANSWER") && (
        <QuestionAnswer
          section_id={props.section_id}
          initiative_id={props.initiative_id}
          questionStartTime={questionStartTime!}
          isCurrentQuestion={isCurrentQuestion}
          question={props.question}
          isQuestionResponseLoading={props.isQuestionResponseLoading}
          onQuestionAttempt={(...details) => {
            setPaused(true);
            setTimeout(() => {
              props.onQuestionAttempt(...details);
            }, 50);
          }}
        />
      )}


      {props.isCorrect && (
        <QuestionExplanation
          initiative_id={props.initiative_id}
          section_id={props.section_id}
          question_id={props.question.id}
          uuid={(props.question as any).uuid}
          shouldShowExplanation={
            (props.question as any).isCorrect !== "YES" ||
            (!!props.question.explain_on_correct &&
              props.previousQuestionId !== props.question.id)
          }
          isCorrect={(props.question as any).isCorrect}
          explanation={props.question.explanation}
          attemptFeedbackMessage={(props.question as any).message}
          onComplete={onExplanationDone}
          userListenedToElementSpeech={props.userListenedToElementSpeech}
        />
      )}

      {blocksShown.includes("ADDITIONAL-CONTENT") && (
        <QuestionAdditionalContent
          isCorrect={props.isCorrect}
          initiative_id={props.initiative_id}
          section_id={props.section_id}
          question_id={props.question.id}
          uuid={(props.question as any).uuid}
          onComplete={questionFlowComplete}
          question={props.question}
        />
      )}

      {blocksShown.includes("DOT_SEPARATOR") && (
        <FadeIn>
          <DotSeparator />
        </FadeIn>
      )}
      <br />
      <br />
    </div>
  );
};
