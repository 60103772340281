import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { NextSectionConnector } from "../../components/next-section-connector/next-section-connector";
import { Question } from "../../components/question/question.component";
import {
  AnswerEntity,
  GetInitiativeQuestionOutput,
  InitiativeLearnerProgressOutput,
  LearnerDetailsOutput,
  QuestionAttemptOutput,
  QuestionEntity,
} from "../../generated/graphql";
import "./section-questions.scss";

import { useHistory } from "react-router-dom";
import { getHideHeader } from "../../AppFont";
import { ClosingSequence } from "../../components/closing-sequence/closing-sequence";
import { LearnerProgress } from "../../components/learner-progress/learner-progress";
import { TypeText } from "../../components/type-text/type-text";
import { eventBus } from "../../event-bus/event-bus";

const getStartDiscussionMessage = (first_name: string) => {
  const messages = [
    `${first_name}, let's start our discussion.`,
    // `${first_name}, I would love to hear from you now.`,
    // `I hope you found that interesting, ${first_name}. I look forward to hearing from you.`,
    // `I hope you found that interesting. I look forward to hearing from you now.`,
  ]
  return messages[Math.floor(Math.random() * messages.length)];
}

function shuffle(array: AnswerEntity[]): AnswerEntity[] {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

interface Props {
  sectionId: number;
  initiativeId: number;
  learnerDetails: LearnerDetailsOutput;
  refdocs: Object[];
  userListenedToElementSpeech: boolean;
  isInitiativeAlreadyCompleted?: boolean;
}

export interface IQuestionAttempt {
  (
    uuid: any,
    question_id: number,
    answer_id: number,
    answer_text: string,
    is_ml: boolean,
    attempt_time: number
  ): void;
}

interface QuestionAttemptResponse {
  attemptQuestion: QuestionAttemptOutput;
}

const ATTEMPT_QUESTION = gql`
  mutation AttemptQuestion(
    $initiative_id: Int!
    $question_id: Int!
    $answer_id: Int!
    $answer_text: String!
    $is_ml: Boolean!
    $attempt_time: Int!
  ) {
    attemptQuestion(
      initiative_id: $initiative_id
      question_id: $question_id
      answer_id: $answer_id
      answer_text: $answer_text
      attempt_time: $attempt_time
      is_ml: $is_ml
    ) {
      isCorrect
      question_id
      message
    }
  }
`;

const GET_NEXT_QUESTION = gql`
  mutation NextQuestionForInitiative($initiative_id: Int!) {
    getQuestionForInitiative(initiative_id: $initiative_id) {
      isInitiativeComplete
      question {
        id
        uuid @client
        question_text
        content
        content_html @client
        static_content_html @client
        static_content
        post_content
        show_additional_content
        explanation
        section_id
        is_ml
        explain_on_correct
        correct_explanation
        answers {
          id
          content
          content_html @client
        }
      }
    }
  }
`;

const GET_INITIATIVE_PROGRESS = gql`
  query GetLearnerProgress($initiative_id: Int!) {
    getLearnerProgressForInitiative(initiative_id: $initiative_id) {
      progress
    }
  }
`;

interface LearnerProgressData {
  getLearnerProgressForInitiative: InitiativeLearnerProgressOutput;
}

interface NextQuestionData {
  getQuestionForInitiative: GetInitiativeQuestionOutput;
}

export const SectionQuestions = (props: Props) => {
  // const sectionId = +(props as any).match.params.sectionid;
  // const initiativeId = +(props as any).match.params.initiativeid;

  const { sectionId, initiativeId, isInitiativeAlreadyCompleted } = props;

  const {
    loading,
    error,
    data,
    refetch: refetchLearnerProgress,
  } = useQuery<LearnerProgressData>(GET_INITIATIVE_PROGRESS, {
    variables: { user_id: 1, initiative_id: initiativeId },
  });

  const [currentQuestionUUID, setCurrentQuestionUUid] = useState(0);
  const [isInitiativeComplete, setInitiativeComplete] = useState(false);
  const [isQuestionListShown, setShowQuestionList] = useState(false);
  const [questions, setQuestions] = useState<QuestionEntity[]>([]);
  const [nextSectionId, setNextSectionId] = useState(0);
  const history = useHistory();
  const [startDiscussionMessage] = useState(getStartDiscussionMessage(props.learnerDetails.first_name));

  const navigateToNextSection = () => {
    const hideHeader = getHideHeader();
    if(hideHeader) {
      history.replace(
        `/initiative/${initiativeId}/section/${nextSectionId}/sort/2?hideHeader`
      );
    } else {
      history.replace(
        `/initiative/${initiativeId}/section/${nextSectionId}/sort/2`
      );
    }
  };

  const getNextQuestion = () => {
    getNextQuestionMutation({
      variables: {
        initiative_id: initiativeId,
        user_id: 1,
      },
    });
  };

  useEffect(() => {
    getNextQuestion();
  }, []);

  const onQuestionFlowComplete = () => {
    setFirstQuestionAttempted(true);
    getNextQuestion();
  };

  const [firstQuestionAttempted, setFirstQuestionAttempted] = useState(false);

  const dispatchEnableScroll = () => {
    eventBus.dispatch("ENABLE_AUTO_SCROLL", {});
  };

  const dispatchDisableScroll = () => {
    eventBus.dispatch("DISABLE_AUTO_SCROLL", {});
  };

  const scrollDown100 = () => {
    setTimeout(() => {
      let container = document.getElementsByClassName(
        "section-content-container"
      )[0];
      container.scrollTo({
        top: container.scrollTop + 500,
        behavior: "smooth",
      });
    }, 300);
  };

  const [getNextQuestionMutation] = useMutation<NextQuestionData>(
    GET_NEXT_QUESTION,
    {
      update: (cache, response) => {
        if (response.data?.getQuestionForInitiative.isInitiativeComplete) {
          setInitiativeComplete(true);
          setCurrentQuestionUUid(0);
        }
        setTimeout(() => {
          refetchLearnerProgress();
        }, 1000);
        if (
          response.data?.getQuestionForInitiative.question?.section_id !==
          sectionId
        ) {
          if (response.data?.getQuestionForInitiative.question) {
            setNextSectionId(
              response.data?.getQuestionForInitiative.question?.section_id!
            );
          }
        } else {
          if (
            response.data.getQuestionForInitiative.question &&
            response.data.getQuestionForInitiative.question.static_content
          ) {
            const static_content =
              response.data.getQuestionForInitiative.question.static_content;
            if (static_content.blocks && static_content.blocks.length) {
              dispatchDisableScroll();
            } else {
              dispatchEnableScroll();
            }
          } else {
            dispatchEnableScroll();
          }

          const question = response.data?.getQuestionForInitiative.question;
          const answers = shuffle(response.data?.getQuestionForInitiative.question.answers);
          
          setQuestions([
            ...questions,
            {
              ...question,
              answers,
            },
          ]);
          setCurrentQuestionUUid(
            response.data?.getQuestionForInitiative.question.uuid
          );
        }
      },
      onError: () => {
        history.replace("/home");
      }
    }
  );

  const [
    attemptQuestion,
    { loading: isQuestionResponseLoading },
  ] = useMutation<QuestionAttemptResponse>(ATTEMPT_QUESTION, {
    update: (cache, response) => {
      setQuestions((list) => {
        const data = list.map((item, index) => {
          if (index === questions.length - 1) {
            const updatedItem = {
              ...item,
              isCorrect: response.data?.attemptQuestion.isCorrect!,
              message: response.data?.attemptQuestion.message,
            };

            return updatedItem;
          }

          return item;
        });
        return data;
      });
    },
  });

  const onQuestionAttempt: IQuestionAttempt = (
    uuid: any,
    question_id: number,
    answer_id: number,
    answer_text: string,
    is_ml: boolean,
    attempt_time: number
  ) => {
    setCurrentQuestionUUid(uuid);
    attemptQuestion({
      variables: {
        initiative_id: initiativeId,
        question_id,
        answer_id,
        answer_text,
        is_ml,
        attempt_time: parseInt(attempt_time + ""),
      },
    });
  };

  if(isInitiativeAlreadyCompleted && !isQuestionListShown) {
    return <ClosingSequence initiativeId={initiativeId} />;
  }

  return (
    <div className="section-questions-container">
      <div
        className={`mentor-communication ${
          firstQuestionAttempted ? "fade-content" : ""
        }`}
      >
        <br />
        <TypeText
          delay={1000}
          onComplete={() => {
            setShowQuestionList(true);
            scrollDown100();
          }}
        >
          {startDiscussionMessage}
        </TypeText>
      </div>
      {isQuestionListShown && (
        <div>
          <LearnerProgress
            progress={data?.getLearnerProgressForInitiative.progress!}
          />
          {questions.map((question, index) => (
            <Question
              userListenedToElementSpeech={props.userListenedToElementSpeech}
              section_id={props.sectionId}
              initiative_id={props.initiativeId}
              isCorrect={(question as any).isCorrect}
              learnerDetails={props.learnerDetails}
              previousQuestionId={
                questions.length > 1 ? questions[questions.length - 2].id : -1
              }
              questionIndex={index}
              onQuestionAttempt={onQuestionAttempt}
              onQuestionFlowComplete={onQuestionFlowComplete}
              key={(question as any).uuid}
              currentQuestionUUID={currentQuestionUUID}
              isQuestionResponseLoading={isQuestionResponseLoading}
              question={question}
            />
          ))}
          {nextSectionId !== 0 && nextSectionId !== sectionId && (
            <NextSectionConnector
              currentSectionId={sectionId}
              navigateToNextSection={navigateToNextSection}
              nextSectionId={nextSectionId}
              refdocs={props.refdocs}
            />
          )}

          {isInitiativeComplete && (
            <ClosingSequence initiativeId={initiativeId} />
          )}
        </div>
      )}
      <br />
      <br />
    </div>
  );
};
