import React, { useEffect, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { LearnerDetailsOutput } from "../../generated/graphql";
import classNames from "classnames";
import "./tts.css";
import { PauseIcon, PlayIcon, RefreshIcon } from "@heroicons/react/outline";

type iProps = {
    connector: string;
    url: string;
    id: number | string;
    sequence?: string;
    showTextLabel?: boolean;
    onUserInitiate?: () => void;
    className?: string;
    pause?: boolean;
}

export default function TTS(props: iProps) {
    const { connector, url, id, sequence, showTextLabel, onUserInitiate, className, pause } = props;

    const [showAudio, setShowAudio] = useState(false);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isAudioLoading, setAudioLoading] = useState(false);
    const [isPlaying, setPlaying] = useState(false);

    const audioElement = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if(pause && audioElement.current) {
            audioElement.current.pause();
            setPlaying(false);
        }
    }, [pause]);

    const onAudioLoad = () => {
        setAudioLoaded(true);
        setAudioLoading(false);
        setPlaying(true);
    }

    const onAudioEnd = () => {
        setPlaying(false);
    }

    const clickHandler = () => {

        if(pause) {
            return false;
        }

        if (!showAudio) {
            setShowAudio(true);
            setAudioLoading(true);
            if(onUserInitiate) {
                onUserInitiate();
            }
            return false;
        }

        if (!audioLoaded) {
            return false;
        }

        if (audioElement) {
            if (audioElement.current?.paused) {
                audioElement.current.play();
                setPlaying(true);
            } else {
                audioElement.current?.pause();
                setPlaying(false);
            }
        }
    }

    return (
        <>
            <div
                className={classNames("flex items-center cursor-pointer justify-start rounded-md py-2", className)}
                onClick={clickHandler}>
                <div className="rounded-full py-2 pr-2">
                    {showAudio && isAudioLoading && <RefreshIcon className="h-8 w-8 text-gray-600 animate-spin" />}
                    {(audioLoaded && isPlaying) && <PauseIcon className="h-8 w-8 text-gray-600" />}
                    {(showAudio && (audioLoaded && !isPlaying)) && <PlayIcon className="h-8 w-8 text-gray-600" />}
                    {!showAudio && <img src="https://images.vexels.com/media/users/3/130896/isolated/preview/1ab44b49cac945623c155db714a3caa2-headphone-flat-icon-silhouette.png" className="h-8 w-8 text-gray-600" />}
                </div>
                {showTextLabel && <div className="ml-1 mt-0.5 text-base">
                    Listen
                </div>}
            </div>

            {showAudio && <div className="relative hidden">
                <audio ref={audioElement} autoPlay className="block w-full" controls onEnded={onAudioEnd} onLoadedData={onAudioLoad}>
                    <source src={`${url}?id=${id}&connector=${encodeURIComponent(connector)}${sequence ? "&sequence=" + encodeURIComponent(sequence) : ""}`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>}
        </>
    )
}