import React, { useEffect, useState } from "react";
import {
  LearnerDetailsOutput,
  QuestionEntity,
} from "../../../../generated/graphql";
import { AnimatedContent } from "../../../animated-content/animated-content";
import { QuestionStarter } from "../question-starter/question-starter";
import "./question-content.scss";
import TTS from "../../../tts/tts";

interface Props {
  onComplete: Function;
  question: QuestionEntity;
  previousQuestionId: number;
  learnerDetails: LearnerDetailsOutput;
  questionIndex: number;
  userListenedToElementSpeech?: boolean;
  pause?: boolean;
}

export const QuestionContent = (props: Props) => {
  const [connectorLanguageShown, setConnectorLanguageShown] = useState(false);
  const [showConnectorLanguage, setShowConnectorLanguage] = useState(false);

  useEffect(() => {
    if (
      props.questionIndex === 0 &&
      !(
        props.question.static_content &&
        props.question.static_content.blocks &&
        props.question.static_content.blocks.length
      )
    ) {
      setConnectorLanguageShown(true);
    } else {
      setShowConnectorLanguage(true);
    }
  });

  return (
    <div className="question-content">
      {showConnectorLanguage && (
        <div className="clear-both">
          {/* <AnnotationIcon className="w-6 h-6 float-left mt-1 mr-1 text-blue-400" /> */}
          <QuestionStarter
            onQuestionStarterComplete={() => setConnectorLanguageShown(true)}
            previousQuestionId={props.previousQuestionId}
            question={props.question}
            learnerDetails={props.learnerDetails}
          />
        </div>
      )}

      {connectorLanguageShown && (
        <div className="clear-both flex pt-1 items-center">
          {props.userListenedToElementSpeech && <div className="pr-3">
            <TTS pause={props.pause}
              sequence={props.question.answers.map(a => a.id).join(",")} 
              id={props.question.id} 
              key={props.question.id} 
              connector={""} 
              url="/api2/question/speech" 
            />
          </div>}
          <div className="flex-1">
            <AnimatedContent
              mode="QUESTION"
              onComplete={props.onComplete}
              data={{
                blocks: props.question.content.blocks.map((block: any) => ({
                  ...block,
                  type: block.type === "header" ? "paragraph" : block.type,
                })),
              }}
            />
          </div>
        </div>
      )}

      <br />
    </div>
  );
};
