import React, { useState } from "react";
import { eventBus } from "../../../../event-bus/event-bus";
import {
  LearnerDetailsOutput,
  QuestionEntity,
} from "../../../../generated/graphql";
import { AnimatedContent } from "../../../animated-content/animated-content";
import { AnswerList } from "../../../answer-list/answer-list";
import { AnswerText } from "../../../answer-text/answer-text";
import { TypeText } from "../../../type-text/type-text";
import { IOnAnswerSelect } from "../../question.component";
import "./question-answer.scss";
import { IQuestionAttempt } from "../../../../screens/section-questions/section-questions";

interface Props {
  initiative_id: number;
  section_id: number;
  isCurrentQuestion: boolean;
  question: QuestionEntity;
  isQuestionResponseLoading: boolean;
  onQuestionAttempt: IQuestionAttempt;
  questionStartTime: Date;
}

export const QuestionAnswer = (props: Props) => {
  const [selectedAnswerId, setSelectedAnswerId] = useState(0);
  const onAnswerSelect: IOnAnswerSelect = (
    answer_id: number,
    answer_text: string
  ) => {
    setSelectedAnswerId(answer_id);
    document
      .querySelectorAll(`#mstr-question-${props.question.id} video`)
      .forEach((video: any) => video.pause());

    const now = new Date();
    console.log(
      "Time for attempt: ",
      now.getTime() - props.questionStartTime.getTime()
    );

    eventBus.dispatch("USER_EVENT", {
      event: "QUESTION_ATTEMPT",
      details: {
        attempt_time: now.getTime() - props.questionStartTime.getTime(),
        question_id: props.question.id,
        section_id: props.section_id,
        initiative_id: props.initiative_id,
      },
    });

    props.onQuestionAttempt(
      (props.question as any).uuid,
      props.question.id,
      answer_id,
      answer_text,
      props.question.is_ml,
      now.getTime() - props.questionStartTime.getTime(),
    );
  };
  return (
    <div>
      {props.question.is_ml && (
        <AnswerText
          isCurrentQuestion={props.isCurrentQuestion}
          isQuestionResponseLoading={props.isQuestionResponseLoading}
          onAnswerSelect={onAnswerSelect}
          isCorrect={(props.question as any).isCorrect}
          showLoading={
            props.isQuestionResponseLoading && props.isCurrentQuestion
          }
        />
      )}
      {!props.question.is_ml && (
        <AnswerList
          answers={props.question.answers}
          isQuestionResponseLoading={props.isQuestionResponseLoading}
          selectedAnswerId={selectedAnswerId}
          isCurrentQuestion={props.isCurrentQuestion}
          isAttemptCorrect={(props.question as any).isCorrect}
          onAnswerSelect={onAnswerSelect}
        />
      )}
    </div>
  );
};
