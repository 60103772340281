import React, { useState } from "react";
import { getFontSize } from "../../AppFont";
import { TypeText } from "../type-text/type-text";
import "./section-start-connector.scss";
import TTS from "../tts/tts";

interface Props {
  onComplete: Function;
  section_header: string;
  sortid: number;
  sectionId: number;
  setUserListenedToElementSpeech: React.Dispatch<React.SetStateAction<boolean>>;
  pause?: boolean;
}

const getConnectorLanguageForFirstElementOfInitiative = () => {
  const messages = [
    "Our first discussion point is in regards to: ",
    "Let's begin our discussion today in regards to: ",
    "Today we will be talking about: ",
    "Today we will be discussing: ",
  ]
  return messages[Math.floor(Math.random() * messages.length)];
}

export const SectionStartConnector = (props: Props) => {
  const [message] = useState(
    props.sortid == 1
      ? getConnectorLanguageForFirstElementOfInitiative()
      : "Our next discussion point is in regards to: "
  );

  const { pause } = props;

  return (
    <div className="section-start-connector mentor-communication"
      style={{
        fontSize: getFontSize(),
      }}
    >
      <br />

      <TTS pause={pause} onUserInitiate={() => {
        props.setUserListenedToElementSpeech(true);
      }} showTextLabel={true} id={props.sectionId} connector={message + " " + props.section_header} url="/api2/chapter/speech" />

      <br />
      <TypeText onComplete={() => props.onComplete()}>
        {message}
        <b>{props.section_header}.</b>
      </TypeText>
      <br />
    </div>
  );
};
